<template>
    <div class="dating-manner">
        <StackRouterHeaderBar />
        <main class="main">
            <FeedbackTop
                class="m-b-40"
                :title="`${
                    user.name || user.profile.nickname
                }님은<br /><span class='c-primary f-bold'>매너 있는</span> 분이었나요?`"
            />
            <FeedbackOptionItems v-model="items" @select="onSelectItems" />
        </main>
    </div>
</template>

<script>
import FeedbackTop from './components/FeedbackTopComponent'
import FeedbackOptionItems from './components/FeedbackOptionItems'
import datingService from '@/services/dating'

export default {
    name: 'DatingMannerPage',
    components: { FeedbackTop, FeedbackOptionItems },
    props: {
        user: Object,
    },
    data: () => ({
        items: [],
    }),
    methods: {
        async init() {
            try {
                const options = await this.$store.dispatch('filterFeedbackOptionByCategory', 'is_manner')
                this.items = options.map(option => {
                    this.$set(option, 'selected', false)
                    return option
                })
            } catch (e) {}
        },
        onSelectItems(idx) {
            this.$store.commit('setSelectedFeedbacks', { category: 'manner', feedbacks: [{ id: this.items[idx].id }] })
            // 매너 없다고 한 경우
            if (this.items[idx].id === 51) {
                this.$stackRouter.push({
                    name: 'DatingNoMannerPage',
                })
            } else {
                this.submit()
            }
        },
        async submit() {
            try {
                this.$loading(true)
                const { dating, payload } = await this.$store.dispatch('prepareFeedbackPayload')

                await datingService.feedback(dating.id, payload)
                await this.$store.dispatch('loadDatingSuccess')
                this.$toast.success('COMPLETE_SUBMIT')
                this.$stackRouter.clear()
            } catch (e) {
                this.$toast.error(e.msg)
            } finally {
                this.$loading(false)
            }
        },
    },
    mounted() {
        this.init()
    },
}
</script>

<style lang="scss" scoped>
.dating-manner {
    .main {
        padding: 0 16px;
    }
}
</style>
